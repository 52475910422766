tinymce.Resource.add('tinymce.html-i18n.help-keynav.ru',
'<h1>Начните управление с помощью клавиатуры</h1>\n' +
  '\n' +
  '<dl>\n' +
  '  <dt>Фокус на панели меню</dt>\n' +
  '  <dd>Windows или Linux: Alt+F9</dd>\n' +
  '  <dd>macOS: &#x2325;F9</dd>\n' +
  '  <dt>Фокус на панели инструментов</dt>\n' +
  '  <dd>Windows или Linux: Alt+F10</dd>\n' +
  '  <dd>macOS: &#x2325;F10</dd>\n' +
  '  <dt>Фокус на нижнем колонтитуле</dt>\n' +
  '  <dd>Windows или Linux: Alt+F11</dd>\n' +
  '  <dd>macOS: &#x2325;F11</dd>\n' +
  '  <dt>Фокус на контекстной панели инструментов</dt>\n' +
  '  <dd>Windows, Linux или macOS: Ctrl+F9\n' +
  '</dl>\n' +
  '\n' +
  '<p>Первый доступный для управления элемент интерфейса будет выделен цветом или подчеркнут (если он находится\n' +
  '  в пути элементов нижнего колонтитула).</p>\n' +
  '\n' +
  '<h1>Переход между разделами пользовательского интерфейса</h1>\n' +
  '\n' +
  '<p>Чтобы перейти из текущего раздела интерфейса в следующий, нажмите <strong>Tab</strong>.</p>\n' +
  '\n' +
  '<p>Чтобы перейти из текущего раздела интерфейса в предыдущий, нажмите <strong>Shift+Tab</strong>.</p>\n' +
  '\n' +
  '<p><strong>Вкладки</strong> разделов интерфейса расположены в следующем порядке:\n' +
  '\n' +
  '<ol>\n' +
  '  <li>Панель меню</li>\n' +
  '  <li>Группы панели инструментов</li>\n' +
  '  <li>Боковая панель</li>\n' +
  '  <li>Путь элементов нижнего колонтитула</li>\n' +
  '  <li>Подсчет слов/символов в нижнем колонтитуле</li>\n' +
  '  <li>Брендовая ссылка в нижнем колонтитуле</li>\n' +
  '  <li>Угол для изменения размера окна редактора</li>\n' +
  '</ol>\n' +
  '\n' +
  '<p>Если раздел интерфейса отсутствует, он пропускается.</p>\n' +
  '\n' +
  '<p>Если при управлении с клавиатуры фокус находится на нижнем колонтитуле, а видимая боковая панель отсутствует, то при нажатии сочетания клавиш <strong>Shift+Tab</strong>\n' +
  '  фокус переносится на первую группу панели инструментов, а не на последнюю.\n' +
  '\n' +
  '<h1>Переход между элементами внутри разделов пользовательского интерфейса</h1>\n' +
  '\n' +
  '<p>Чтобы перейти от текущего элемента интерфейса к следующему, нажмите соответствующую <strong>клавишу со стрелкой</strong>.</p>\n' +
  '\n' +
  '<p>Клавиши со стрелками <strong>влево</strong> и <strong>вправо</strong> позволяют</p>\n' +
  '\n' +
  '<ul>\n' +
  '  <li>перемещаться между разными меню в панели меню.</li>\n' +
  '  <li>открывать разделы меню.</li>\n' +
  '  <li>перемещаться между кнопками в группе панели инструментов.</li>\n' +
  '  <li>перемещаться между элементами в пути элементов нижнего колонтитула.</li>\n' +
  '</ul>\n' +
  '\n' +
  '<p>Клавиши со стрелками <strong>вниз</strong> и <strong>вверх</strong> позволяют\n' +
  '\n' +
  '<ul>\n' +
  '  <li>перемещаться между элементами одного меню.</li>\n' +
  '  <li>перемещаться между элементами всплывающего меню в панели инструментов.</li>\n' +
  '</ul>\n' +
  '\n' +
  '<p>При использовании <strong>клавиш со стрелками</strong> вы будете циклически перемещаться по элементам в пределах выбранного раздела интерфейса.</p>\n' +
  '\n' +
  '<p>Чтобы закрыть открытое меню, его раздел или всплывающее меню, нажмите клавишу <strong>Esc</strong>.\n' +
  '\n' +
  '<p>Если фокус находится наверху какого-либо раздела интерфейса, нажатие клавиши <strong>Esc</strong> также приведет\n' +
  '  к выходу из режима управления с помощью клавиатуры.</p>\n' +
  '\n' +
  '<h1>Использование элемента меню или кнопки на панели инструментов</h1>\n' +
  '\n' +
  '<p>Когда элемент меню или кнопка панели инструментов будут выделены, нажмите <strong>Return</strong>, <strong>Enter</strong>\n' +
  '  или <strong>Space</strong>, чтобы их активировать.\n' +
  '\n' +
  '<h1>Управление в диалоговом окне без вкладок</h1>\n' +
  '\n' +
  '<p>При открытии диалогового окна без вкладок фокус переносится на первый интерактивный компонент.</p>\n' +
  '\n' +
  '<p>Для перехода между интерактивными компонентами диалогового окна нажимайте <strong>Tab</strong> или <strong>Shift+Tab</strong>.</p>\n' +
  '\n' +
  '<h1>Управление в диалоговом окне с вкладками</h1>\n' +
  '\n' +
  '<p>При открытии диалогового окна с вкладками фокус переносится на первую кнопку в меню вкладок.</p>\n' +
  '\n' +
  '<p>Для перехода между интерактивными компонентами этой вкладки диалогового окна нажимайте <strong>Tab</strong> или\n' +
  '  <strong>Shift+Tab</strong>.</p>\n' +
  '\n' +
  '<p>Для перехода на другую вкладку диалогового окна переместите фокус на меню вкладок, а затем используйте <strong>клавиши со стрелками</strong>\n' +
  '  для циклического переключения между доступными вкладками.</p>\n');