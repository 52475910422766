<template>
  <div>
    <label v-if="label">
      {{ label }}
    </label>
    <textarea ref="textarea"></textarea>

    <portal
      v-if="isLoading"
      to="tiny-loader"
    >
      <div class="loader-wrap">
        <CSpinner color="primary" size="lg" class="loader"/>
      </div>
    </portal>
  </div>
</template>

<script>
import tinymce from '@/assets/js/tinymce/js/tinymce/tinymce.min'

import '@/assets/js/tinymce/js/tinymce/icons/default/icons.min';
import '@/assets/js/tinymce/js/tinymce/themes/silver/theme.min';
import '@/assets/js/tinymce/js/tinymce/models/dom/model.min';

import '@/assets/js/tinymce/js/tinymce/langs/ru.js';

import '@/assets/js/tinymce/js/tinymce/plugins/autolink/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/link/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/image/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/charmap/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/code/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/fullscreen/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/media/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/table/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/searchreplace/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/visualchars/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/codesample/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/nonbreaking/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/insertdatetime/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/advlist/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/lists/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/wordcount/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/emoticons/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/emoticons/js/emojis.js';
import '@/assets/js/tinymce/js/tinymce/plugins/accordion/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/help/plugin.min';
import '@/assets/js/tinymce/js/tinymce/plugins/help/js/i18n/keynav/ru.js';
import '@/assets/js/tinymce/js/tinymce/plugins/help/js/i18n/keynav/en.js';
import axios from "axios";

export default {
  name: "Tinymce",
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    height: {
      type: String|Number,
      default: 220
    }
  },
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
    if (this.$store.getters.darkMode) {
      import('@/assets/js/tinymce/js/tinymce/skins/ui/oxide-dark/skin.min.css')
      import('@/assets/js/tinymce/js/tinymce/skins/ui/oxide-dark/content.min.css')
    } else {
      import('@/assets/js/tinymce/js/tinymce/skins/ui/oxide/skin.min.css')
      import('@/assets/js/tinymce/js/tinymce/skins/ui/oxide/content.min.css')
    }
    this.$nextTick(() => {
      tinymce.init({
        skin: 'oxide',
        target: this.$refs.textarea,
        height: this.height,
        language: this.$root.$i18n.locale,
        plugins: 'searchreplace autolink ' +
            'code visualchars fullscreen image link media ' +
            'codesample table charmap nonbreaking insertdatetime advlist ' +
            'lists wordcount emoticons accordion help',
        menubar: 'file edit view insert format tools table help',
        toolbar: "undo redo | accordion accordionremove | blocks fontsize | " +
            "bold italic underline strikethrough | align numlist bullist | link image | " +
            "table media | lineheight outdent indent| " +
            "forecolor backcolor removeformat | charmap emoticons | code " +
            "fullscreen | codesample",
        image_caption: true,
        toolbar_mode: 'sliding',
        contextmenu: '',
        image_title: true,
        relative_urls: false,
        remove_script_host: true,
        file_picker_callback: (cb, value, meta) => {
          const input = document.createElement('input');
          const isImage = meta.filetype === 'image'

          input.setAttribute('type', 'file');
          input.setAttribute('accept', isImage ? 'image/*' : 'audio/*');

          input.addEventListener('change', (e) => {
            this.isLoading = true

            const file = e.target.files[0];

            const reader = new FileReader();
            reader.addEventListener('load', () => {
              const formData = new FormData()

              formData.append(isImage ? 'image' : 'audio', file)

              axios.post('/api/v1/upload',
                  formData,
                  {
                    baseURL: process.env.VUE_APP_UPLOAD_MEDIA_BASE_URL
                  })
                  .then((res) => {
                    const src = res?.data?.image || res?.data?.audio

                    if (src) {
                      cb(`${process.env.VUE_APP_BACK_URL}${src}`)
                    } else {
                      this.$toastr.error(this.$t('lk.training.errorSave'))
                    }
                  })
                  .catch((er) => {
                    console.error(er)
                  })
                  .finally(() => {
                    this.isLoading = false
                  })
            });
            reader.readAsDataURL(file);
          });

          input.click();
        },
        media_dimensions: false,
        media_poster: false,
        media_alt_source: false,
        media_live_embeds: true,
        content_style: `
        audio,
        iframe,
        img {
          max-width: 100%;
          height: auto;
        }
        `,
        setup: (editor) => {
          editor.on('init', () => {
            editor.setContent(this.value || '')
          })
          editor.on('change', () => {
            this.$emit('input', editor.getContent())
          });
        }
      });
    })
  },
}
</script>

<style lang="scss">
.tox-promotion {
  display: none !important;
}
</style>

<style lang="scss" scoped>
.loader-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(black, .4);
}
</style>
