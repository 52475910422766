var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.label
        ? _c("label", [_vm._v(" " + _vm._s(_vm.label) + " ")])
        : _vm._e(),
      _c("textarea", { ref: "textarea" }),
      _vm.isLoading
        ? _c("portal", { attrs: { to: "tiny-loader" } }, [
            _c(
              "div",
              { staticClass: "loader-wrap" },
              [
                _c("CSpinner", {
                  staticClass: "loader",
                  attrs: { color: "primary", size: "lg" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }