var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "VideoListModal",
      attrs: {
        scrollable: "",
        title: _vm.$t("lk.videoEncrypt.chooseVideo").toString(),
        centered: "",
        size: "lg",
        show: _vm.show,
      },
      on: {
        "update:show": function ($event) {
          _vm.$emit("update:show", $event)
          _vm.checkedVideo = null
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "CLoadingButtonCustom",
                {
                  staticClass: "mr-2 h-44",
                  attrs: {
                    block: "",
                    color: "primary",
                    loading: _vm.loading,
                    disabled: _vm.loading || !_vm.checkedVideo,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.selectVideo.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("lk.subscribe.pay")) + " ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "CCard",
        {
          staticClass:
            "d-flex justify-content-center align-items-center shadow-none VideoListModal__card",
          attrs: { color: "second-card-bg" },
        },
        [
          _vm.loading
            ? [
                _c("CSpinner", {
                  attrs: { id: "get-current-mixin-loader", color: "primary" },
                }),
              ]
            : [
                _c(
                  "CCardBody",
                  { staticClass: "h-100 w-100 d-flex flex-column" },
                  [
                    _c("div", { staticClass: "table-wrap" }, [
                      _c(
                        "table",
                        { staticClass: "table mb-0 VideoListModal__table" },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(
                                  _vm._s(_vm.$t("lk.videoEncrypt.table.poster"))
                                ),
                              ]),
                              _c("th", [
                                _vm._v(
                                  _vm._s(_vm.$t("lk.videoEncrypt.table.status"))
                                ),
                              ]),
                              _c("th", [
                                _vm._v(
                                  _vm._s(_vm.$t("lk.videoEncrypt.table.video"))
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            { attrs: { color: "primary" } },
                            _vm._l(_vm.filteredData, function (item) {
                              return _c(
                                "tr",
                                {
                                  key: item.id,
                                  class: { checked: _vm.isChecked(item.id) },
                                  on: {
                                    click: function ($event) {
                                      _vm.checkedVideo = item
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "td",
                                    { staticClass: "poster" },
                                    [
                                      item.poster
                                        ? _c("img", {
                                            attrs: { src: item.poster },
                                          })
                                        : _c("CIcon", {
                                            attrs: { name: "cilImage" },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            `lk.videoEncrypt.table.statuses['${item.status}']`
                                          )
                                        ) +
                                        " "
                                    ),
                                    item.types.length
                                      ? _c("div", [
                                          _vm._v(
                                            " " + _vm._s(item.types.join(", "))
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c("td", [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "lk.videoEncrypt.table.filename"
                                          )
                                        ) +
                                          ": " +
                                          _vm._s(item.filename)
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("lk.videoEncrypt.table.name")
                                        ) +
                                          ": " +
                                          _vm._s(item.name)
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "lk.videoEncrypt.table.description"
                                          )
                                        ) +
                                          ": " +
                                          _vm._s(item.description)
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "lk.videoEncrypt.table.hls_time"
                                          )
                                        ) +
                                          ": " +
                                          _vm._s(item.hls_time)
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }