<template>
  <CModal
    class="VideoListModal"
    scrollable
    :title="$t('lk.videoEncrypt.chooseVideo').toString()"
    centered
    size="lg"
    :show="show"
    @update:show="$emit('update:show', $event); checkedVideo = null"
  >
   <CCard color="second-card-bg" class="d-flex justify-content-center align-items-center shadow-none VideoListModal__card">
    <template v-if="loading">
      <CSpinner
        id="get-current-mixin-loader"
        color="primary"
      />
    </template>
    <template v-else>
       <CCardBody class="h-100 w-100 d-flex flex-column">
        <div class="table-wrap">
          <table class="table mb-0 VideoListModal__table">
            <thead>
              <tr>
                <th>{{ $t('lk.videoEncrypt.table.poster') }}</th>
                <th>{{ $t('lk.videoEncrypt.table.status') }}</th>
                <th>{{ $t('lk.videoEncrypt.table.video') }}</th>
              </tr>
            </thead>

            <tbody color="primary">
              <tr
                @click="checkedVideo = item"
                :class="{checked: isChecked(item.id)}"
                v-for="item in filteredData"
                :key="item.id"
                >
                <td class="poster">
                  <img v-if="item.poster" :src="item.poster">
                  <CIcon v-else name="cilImage" />
                </td>
                <td>
                  {{ $t(`lk.videoEncrypt.table.statuses['${item.status}']`) }}
                  <div v-if="item.types.length"> {{ item.types.join(', ') }}</div>
                </td>
                <td>
                  <p>{{ $t('lk.videoEncrypt.table.filename') }}: {{ item.filename }}</p>
                  <p>{{ $t('lk.videoEncrypt.table.name') }}: {{ item.name }}</p>
                  <p>{{ $t('lk.videoEncrypt.table.description') }}: {{ item.description }}</p>
                  <p>{{ $t('lk.videoEncrypt.table.hls_time') }}: {{ item.hls_time }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </template>

    </CCard>
    <template v-slot:footer>
        <CLoadingButtonCustom
          block
          color="primary"
          class="mr-2 h-44"
          :loading="loading"
          :disabled="loading || !checkedVideo"
          @click.native="selectVideo"
        >
          {{ $t('lk.subscribe.pay') }}
        </CLoadingButtonCustom>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VideoListModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    videos: {
      type: Array,
      default: () => ([])
    },
    types: {
      type: Array,
      default: () => ([])
    },
  },
  data () {
    return {
      loading: false,
      items: [],
      checkedVideo: null
    };
  },
  computed: {
    filteredData(){
      return this.items.filter((item)=>{
        return !this.videos.find((video)=> video.id === item.id) && (!this.types.length || item.types.filter(t => this.types.includes(t)).length)
      })
    }
  },
  beforeMount () {
  },
  created() {
    this.getItems()
  },
  methods: {
    isChecked(id){
     return this.checkedVideo?.id === id
    },
    selectVideo(){
      this.$emit('selcetVideo', this.checkedVideo)
    },
    async getItems() {
      this.loading = true;
      await axios.get('/api/v1/video-encrypt?statuses=encrypted'
        + (this.types.length ? '&types=' + this.types.join(',') : ''))
        .then(({data}) => {
        this.items = []
        data.data.forEach(el => {
          if(el.id){
            const types = el.types || []
            if(!types.length){
              types.push('private')
            }
            this.items.push({
              id: el.id,
              status: el.status,
              types: types,
              filename: el.filename,
              mime: el.mime || 'application/x-mpegURL',
              name: el.name || '',
              description: el.description || '',
              hls_time: el.hls_time || 10,
              title: el.name || el.filename || el.id,
              poster: el.poster || null,
              playlist: el.playlist || null,
              hash: el.hash || null
            })
          }
        })
      })
        .finally(() => {
          this.loading = false;
        });
    },



  }
};
</script>

<style lang="scss" scoped>
.ccard-body {
  overflow: hidden;
}

.table {
  position: relative;

  thead tr th {
    background: var(--second-card-bg);
    top: -1px;
    z-index: 1;
  }
}

.VideoListModal {
  width: 100%;

  /deep/ .modal-dialog-scrollable .modal-body {
  @media(min-width: 768px) {
    overflow: auto !important;
  }
}

  .table-wrap {
    overflow: auto;
    width: 100%;
    flex-grow: 1;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid var(--subscribe-card-bg);
    color: #20a8d8;
    padding: 12px 24px;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    &-btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

     &__white {
      color: var(--white);
    }
  }

  &__card {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0;
    min-height: 300px;
  }

  .__active {
    transform: rotate(-90deg);
  }

  &__table {

    &__btn {
      display: inline-block;
      transform: rotate(90deg);
      font-stretch: ultra-condensed;
      line-height: 0px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: rgba(#2eb85c, 0.2);
      }
    }

    tbody td {
      cursor: pointer;
      vertical-align: middle;

      &:nth-child(1) {
        text-align: center;
        width: 100px;
      }
      &:nth-child(2) {
        width: 100px;
      }
      &:nth-child(4) {
        width: 160px;
      }

      p{
        margin: 0;
      }
    }
  }

  .modal-header {
    border-bottom: none;
  }
}
.poster img{
  max-width: 80px;
}

@keyframes showSubTableAnim {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.checked{
  background: var(--watch-list-primary) !important;
}
</style>
